<template>
	<div>
		<div class="dropdown">
			<div @click="myFunction()" class="profile dropbtn">
				<div class="icon-profile"></div>
				<div class="nameLogin">{{ nameLogin }}</div>
				<div class="icon-arrow"></div>
			</div>
			<div id="myDropdown" class="dropdown-content">
				<a @click="relatorio">
					<div class="report"></div>Relatório
				</a>
				<a @click="perfil">
					<div class="login-perfil"></div>Perfil
				</a>
				<a @click="logout">
					<div class="login-icon"></div>Sair
				</a>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "Dropdown",
	props: {},
	data() {
		return {
			nameLogin: ''
		}
	},
	created() {

		var operator = JSON.parse(localStorage.getItem("userKey"));

		this.nameLogin = operator.user
	},
	methods: {
		relatorio() {
			var userLogado = localStorage.getItem('userKey')

			var matriculation = 0

			if (userLogado) {
				window.location.href = "https://msv.sempher.com.br/delivery_role";
				/*this.$router.push({
					path: `/matricula?retirada`
				})*/
			} else {
				this.$router.push({
					path: `login`
				})
			}
		},
		report() {


		},
		perfil() {

			var userLogado = localStorage.getItem('userKey')

			var matriculation = 0

			if (userLogado) {
				this.$router.push({
					path: `/perfil`
				})
			} else {
				this.$router.push({
					path: `login`
				})
			}
		},
		logout() {

			localStorage.removeItem('userKey')

			this.$router.push({
				path: '/login'
			})
		},
		myFunction: function () {
			document.getElementById("myDropdown").classList.toggle("show");
		},
		onClick: function (ev) {
			if (
				!ev.target.matches(".dropbtn") &&
				!ev.target.matches(".icon-profile") &&
				!ev.target.matches(".icon-arrow")
			) {
				var dropdown = document.getElementsByClassName("dropdown-content");
				var i;
				for (i = 0; i < dropdown.length; i++) {
					var openDropdown = dropdown[i];
					if (openDropdown.classList.contains("show")) {
						openDropdown.classList.remove("show");
					}
				}
			}
		},
	},
	mounted: function () {
		// Attach event listener to the root vue element
		window.addEventListener("click", this.onClick);
		// Or if you want to affect everything
		// document.addEventListener('click', this.onClick)
	},
	beforeDestroy: function () {
		window.removeEventListener("click", this.onClick);
		// document.removeEventListener('click', this.onClick)
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.nameLogin {
	color: #ffffff;
	margin-left: 10px;
	margin-right: 10px;
}

.router-link {
	padding: 0 !important;
}

.dropdown {
	position: relative;
	display: inline-block;
}

.login-icon {
	position: absolute;
	mask: url(../assets/login.svg);
	height: 16px;
	width: 16px;
	mask-size: 16px;
	background-color: rgba(0, 0, 0, 0.95);
	left: 20px;
}

.login-perfil {
	position: absolute;
	mask: url(../assets/id-card.svg);
	height: 16px;
	width: 16px;
	mask-size: 16px;
	background-color: rgba(0, 0, 0, 0.95);
	left: 20px;
}

.report {
	position: absolute;
	mask: url(../assets/clipboard.svg);
	height: 16px;
	width: 16px;
	mask-size: 16px;
	background-color: rgba(0, 0, 0, 0.95);
	left: 20px;
}

.dropdown-content {
	border: 1px solid rgba(255, 255, 255, 0.5);
	right: 0;
	display: none;
	position: absolute;
	background-color: #f1f1f1;
	min-width: 160px;
	overflow: auto;
	box-shadow: -8px 8px 16px 0px rgba(0, 0, 0, 0.2);
	z-index: 1;
	border-radius: 3px;
	cursor: pointer;
}

.dropdown-content a {
	transition: 0.1s;
	color: rgba(0, 0, 0, 0.95);
	padding: 12px 16px;
	text-decoration: none;
	display: flex;
	align-items: center;
	justify-content: center;
	line-height: 20px;
}

.router-link:hover {
	background-color: #222e3c;
}

.router-link:hover a {
	color: rgba(255, 255, 255, 0.95);
}

.router-link:hover .login-icon {
	background-color: rgba(255, 255, 255, 0.95);
}

.show {
	display: block;
}

.icon-profile {
	width: 17px;
	height: 17px;
	mask: url(../assets/user.svg);
}

.icon-arrow {
	width: 9px;
	height: 9px;
	mask: url(../assets/down-arrow.svg);
}

.icon-profile,
.icon-arrow {
	transition: 0.15s;
	margin-right: 5px;
	background-color: rgba(255, 255, 255, 0.7);
}

.profile {
	cursor: pointer;
	display: flex;
	align-items: center;
	padding: 0px 20px;
}

.profile:hover .icon-profile,
.profile:hover .icon-arrow {
	background-color: #fff !important;
}

@media only screen and (min-height: 1500px) {
	@media only screen and (max-width: 1500px) {
		.icon-profile {
			width: 32px;
			height: 32px;
		}

		.icon-arrow {
			width: 18px;
			height: 18px;
		}
	}
}</style>
