<template>
	<div class="login">
		<div id="login-card" class="login-card">
			<h2 class="login-title">Login</h2>
			<form class="login-form" action="javascript:void(0);" @submit="sendLogin">
				<div class="input-container">
					<Input name="username" placeholder="Usuário" />
				</div>
				<div id="password-container" class="input-container">
					<Input id="password" name="password" placeholder="Senha" type="password" />
				</div>
				<div class="locals">
					<Select name="local_embarcacao" placeholder="Escolha um local" :options="options" />
				</div>
				<div class="btn-container">
					<Button buttonText="Entrar" />
				</div>
				<div>
					<p text-align="center"><a href="https://msv.sempher.com.br/resetpasswordsendemail" target="_blank"
							rel="noopener noreferrer">Esqueceu sua senha?</a></p>
				</div>
			</form>
		</div>
	</div>
</template>

<script>

import { baseApiUrl } from "@/global";

import axios from 'axios'

import Button from "@/components/Button";
import Input from "@/components/Input";
import Select from "@/components/Select"

export default {
	name: "Login",
	data() {
		return {
			options: [
				'ALMOXARIFADO'
			]
		}
	},
	components: { Button, Input, Select },
	methods: {
		sendLogin(e) {

			let userData = {};

			const form = e.target;
			const formData = new FormData(form);
			for (const [inputName, value] of formData) {
				userData[inputName] = value;
			}

			let url = window.location.href;
			var url_action = url.substring(url.indexOf("?") + 1, url.length);

			const method = 'post'
			var rotas = 'login'

			const options = {
				headers: {
					"Accept": "application/json"
				}
			}

			if (!userData.username.length > 0) {

				this.$toasted.global.defaultError({ msg: "O campo usuário é obrigatório!" });
				return;
			}

			if (!userData.password.length > 0) {

				this.$toasted.global.defaultError({ msg: "O campo senha é obrigatório!" });
				return;
			}

			if (userData.local_embarcacao == 0) {

				this.$toasted.global.defaultError({ msg: "Selecione um local!" });
				return;
			}

			var user = {
				user: userData.username,
				pass: userData.password
			}


			axios[method](`${baseApiUrl}/${rotas}`, user, {
				timeout: 5000
			}, options).then(res => {

				console.log(res)

				if (res.status === 200) {

					var user = {
						id: res.data.id,
						user: res.data.name,
						email: res.data.email,
						token: res.data.token,
						local: userData.local_embarcacao
					}

					localStorage.setItem('userKey', JSON.stringify(user))

					this.$router.push({
						path: '/'
					})
				} else if (res.status === 422) {

					this.$toasted.global.defaultError({ msg: "Usuário ou senha incorretos!" });
					return;
				}
			}).catch((e) => {

				this.$toasted.global.defaultError({ msg: e });
				return;

				if (e.code === 'ECONNABORTED') {

				}
			});
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.login {
	width: 100vw;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	background: linear-gradient(to right, #222e3c, #6a86a7, rgb(160, 205, 241));
}

.login-card {
	transition: 0.4s;
	min-width: 250px;
	width: 30%;
	border-radius: 5px;
	background-color: rgba(255, 255, 255, 0.95);
	padding: 3vw;
	box-shadow: 8px 8px 16px 0px rgba(0, 0, 0, 0.2);
}

.login-card-translate {
	transform: translateY(-100px);
}

.login-title {
	font-size: 30px;
	color: rgb(80, 79, 79);
	width: 100%;
	text-align: center;
	margin-bottom: 45px;
	font-weight: 400;
}

.input-container {
	margin-bottom: 15px;
}

.login-form {
	margin-bottom: 15px;
}

.btn-container:not(:last-child) {
	margin-bottom: 15px;
}

.keyboard-container {
	position: absolute;
	bottom: 30px;
	transition: 0.5s;
	transform: translateY(200px);
}

.keyboard-container-translate {
	transform: translateY(0);
}

.locals {
	margin-bottom: 15px;
}

@media only screen and (min-width: 1400px) {
	.login-title {
		font-size: 30px;
	}

	.input-container {
		margin-bottom: 25px;
	}

	.login-form {
		margin-bottom: 25px;
	}

	.btn-container:not(:last-child) {
		margin-bottom: 25px;
	}

}

@media only screen and (max-width: 1200px) {
	.login-card {
		padding: 8vw;
		width: 50%;
	}
}
</style>
